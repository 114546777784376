import {Injectable}  from '@angular/core';
import {BaseService} from '@src/app/shared/services/base.service';
import {environment} from '@src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageService extends BaseService
{
    public prefix: string = environment.storagePrefix;

    public constructor()
    {
        super();
    }

    public write(key: string, data: any)
    {
        localStorage.setItem(`${this.prefix}_${key}`, data);
    }

    public read(key: string, decode?: boolean): string
    {
        const data: string = localStorage.getItem(`${this.prefix}_${key}`);
        return decode ? JSON.parse(data) : data;
    }

    public remove(key: string): void
    {
        localStorage.removeItem(`${this.prefix}_${key}`);
    }
}
