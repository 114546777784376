import {CommonModule}             from '@angular/common';
import {NgModule}                 from '@angular/core';
import {MatDatepickerModule}      from '@angular/material/datepicker';
import {ProductsIndexComponent}   from '@src/app/admin/products/components/index/products-index.component';
import {ProductsRoutingModule}    from '@src/app/admin/products/products-routing.module';
import {AdminModule}              from '@src/app/layout/admin/admin.module';
import {ProductsFormComponent}    from './components/form/products-form.component';
import {CustomProductsComponent}  from './components/index/custom-products/custom-products.component';
import {DefaultProductsComponent} from './components/index/default-products/default-products.component';
import {PricingModalComponent}    from './components/pricing-modal/pricing-modal.component';

@NgModule({
    imports: [
        ProductsRoutingModule,
        AdminModule,
        CommonModule,
        MatDatepickerModule
    ],
    declarations: [
        ProductsIndexComponent,
        DefaultProductsComponent,
        CustomProductsComponent,
        ProductsFormComponent,
        PricingModalComponent
    ],
    exports: [
        ProductsIndexComponent,
        DefaultProductsComponent,
        CustomProductsComponent,
        ProductsFormComponent,
        PricingModalComponent
    ],
})
export class ProductsModule
{
}
