import {NgModule}                 from '@angular/core';
import {ForgotPasswordComponent}  from '@src/app/core/components/forgot-password/forgot-password.component';
import {LoginComponent}           from '@src/app/core/components/login/login.component';
import {ResetPasswordComponent}   from '@src/app/core/components/reset-password/reset-password.component';
import {SharedModule}             from '@src/app/shared/shared.module';
import {NgxChartsModule}          from '@swimlane/ngx-charts';
import {NgOtpInputModule}         from 'ng-otp-input';
import {TourMatMenuModule}        from 'ngx-ui-tour-md-menu';
import {CreatePasswordComponent}  from './components/create-password/create-password.component';
import {DashboardComponent}       from './components/dashboard/dashboard.component';
import {OtpVerificationComponent} from './components/otp-verification/otp-verification.component';
import {SignUpComponent}          from './components/sign-up/sign-up.component';
import {CoreRoutingModule}        from './core-routing.module';

@NgModule({
    imports: [
        CoreRoutingModule,
        SharedModule,
        NgxChartsModule,
        NgOtpInputModule,
        TourMatMenuModule
    ],
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SignUpComponent,
        CreatePasswordComponent,
        DashboardComponent,
        CreatePasswordComponent,
        OtpVerificationComponent
    ],
    exports: [
        LoginComponent,
        SharedModule
    ]
})
export class CoreModule
{
}
