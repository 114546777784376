import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent}                 from '@angular/material/paginator';
import {MatTableDataSource}           from '@angular/material/table';
import {Router}                       from '@angular/router';
import {GlobalService}                from '@src/app/core/services/global.service';
import {ApiService}                   from '@src/app/shared/services/api.service';
import {ThemeService}                 from '@src/app/shared/services/theme.service';
import {WalkthroughService}                      from '@src/app/shared/services/walkthrough/walkthrough.service';
import { Subject } from 'rxjs';
import {takeUntil}                               from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy
{
    public skeletonBackground: '#eeeeee'|'#424242' = '#eeeeee';
    public skeletonTheme: 'progress'|'progress-dark' = 'progress';
    public unsubscribe: Subject<void> = new Subject();
    // Site Status Properties
    public siteStatuses: any[] = [];

    // Latest Pin Activity Properties
    @ViewChild(MatPaginator) public paginator!: MatPaginator;
    public displayedColumns: string[] = ['name', 'status', 'site', 'author', 'updated_at'];
    public latestPinActivity: MatTableDataSource<any> = new MatTableDataSource();
    public totalRows = 0;
    public pageSize = 10;
    public currentPage = 0;
    public pageSizeOptions: number[] = [5, 10, 25, 100];

    // Latest Operative Activity
    @ViewChild(MatPaginator) public paginator2!: MatPaginator;
    public displayedColumnsLOA: string[] = ['name', 'total'];
    public latestOperativeActivity: MatTableDataSource<any> = new MatTableDataSource();
    public totalRowsLOA = 0;
    public pageSizeLOA = 10;
    public currentPageLOA = 0;
    public pageSizeOptionsLOA: number[] = [5, 10, 25];

    public constructor(
        public router: Router,
        public themeService: ThemeService,
        public walkthroughService: WalkthroughService,
        private globalService: GlobalService,
        private apiService: ApiService
    )
    {
        this.globalService.title = 'Dashboard';
        this.walkthroughService.startWalkthrough('dashboard', false);
    }

    public ngOnInit(): void
    {
        this.themeService.theme.pipe(takeUntil(this.unsubscribe))
            .subscribe((theme) => {
                this.skeletonBackground = theme === 'dark-theme' ? '#424242' : '#eeeeee';
                this.skeletonTheme = theme === 'dark-theme' ? 'progress-dark' : 'progress';
            });

        this.apiService.get('dashboard/sites-by-status').subscribe((response: any) => {
            this.siteStatuses = response;
        });

        this.loadLatestPinActivity();
        this.loadLatestOperativeActivity();
    }

    public pinActivityPageChanged(event: PageEvent)
    {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;

        this.loadLatestPinActivity();
    }

    public operativeActivityPageChanged(event: PageEvent)
    {
        this.pageSizeLOA = event.pageSize;
        this.currentPageLOA = event.pageIndex;

        this.loadLatestOperativeActivity();
    }

    private loadLatestPinActivity()
    {
        this.apiService.list('dashboard/latest-pin-activity', this.currentPage + 1, this.pageSize).subscribe(
            (response: any) => {
                this.paginator.pageIndex = this.currentPage;
                this.paginator.length = response.meta.total;
                this.latestPinActivity.data = response.data;
            }
        );
    }

    private loadLatestOperativeActivity()
    {
        this.apiService.list('dashboard/operative-pin-history-count', this.currentPageLOA + 1, this.pageSizeLOA).subscribe(
            (response: any) => {
                this.paginator2.pageIndex = this.currentPageLOA;
                this.paginator2.length = response.meta.total;
                this.latestOperativeActivity.data = response.data;
            }
        );
    }

    public ngOnDestroy(): void
    {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
