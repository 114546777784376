import { BreakpointObserver } from '@angular/cdk/layout';
import {ApplicationRef, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ThemeService
{
    public readonly handset: Observable<boolean>;
    public readonly theme: BehaviorSubject<'light-theme'|'dark-theme'> = new BehaviorSubject<'light-theme'|'dark-theme'>('light-theme');

    constructor(private applicationRef: ApplicationRef, private breakpointObserver: BreakpointObserver)
    {
        const darkModeOn: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (darkModeOn) {
            this.theme.next('dark-theme');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event: MediaQueryListEvent) => {
            const turnOn: boolean = event.matches;

            this.theme.next(turnOn ? 'dark-theme' : 'light-theme');

            this.applicationRef.tick();
        });

        this.handset = this.breakpointObserver.observe('(min-width: 767px)')
                .pipe(map(result => result.matches));
    }

    public toggleTheme(): void
    {
        this.theme.next(this.theme.value === 'dark-theme' ? 'light-theme' : 'dark-theme');
    }
}
