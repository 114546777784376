import {Injectable}               from '@angular/core';
import {AuthService}              from '@src/app/core/services/auth.service';
import {ToastrService}            from 'ngx-toastr';
import {IStepOption, TourService} from 'ngx-ui-tour-md-menu';
import {ApiService}               from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class WalkthroughService
{
    public walkthrough: any = null;

    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private tourService: TourService,
        private toastService: ToastrService
    )
    {
        this.tourService.events$.subscribe(async x => {
            // if (x.name === 'stepShow') {
            //     await this.completeStep(x.value);
            // }

            if (x.name === 'end' && !this.walkthrough?.is_completed) {
                await this.userDroppedOut(this.walkthrough.id);
            }
        });
    }

    public async startWalkthrough(tourReference: string, restartTour: boolean): Promise<void>
    {
        this.setTourSteps(tourReference, restartTour).then((startWalkthrough: boolean) => {
            if (startWalkthrough) {
                this.tourService.start();
            }
        }).catch(() => {
            this.errorMessage();
        });
    }

    private async setTourSteps(feature: string, restartTour: boolean): Promise<boolean>
    {
        return new Promise(async (resolve, reject) => {
            this.walkthrough = this.getWalkthrough(feature);

            if (this.walkthrough) {
                if (this.walkthrough.has_dropped_out || this.walkthrough.is_completed && !restartTour) {
                    resolve(false);
                }

                if (restartTour) {
                    await this.restartTour(feature).then(() => {
                        this.walkthrough = this.getWalkthrough(feature);
                    });
                }

                if (this.walkthrough && this.walkthrough.items.length > 0) {
                    this.tourService.initialize(this.walkthrough.items, {
                        enableBackdrop: false,
                        closeOnOutsideClick: false,
                    });

                    resolve(true);
                }
            }

            reject(false);
        });
    }

    private completeStep(step: IStepOption): Promise<void>
    {
        return new Promise((resolve, reject) => {
            this.apiService.get(`walkthrough-items/${step.stepId}/complete`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    this.walkthrough = this.getWalkthrough(this.walkthrough.feature);
                    resolve();
                },
                error: (error) => reject(error)
            });
        });
    }

    private async userDroppedOut(walkthroughId: string): Promise<void>
    {
        return new Promise((resolve, reject) => {
            debugger;
            this.apiService.get(`walkthroughs/${walkthroughId}/drop-out`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    resolve();
                },
                error: (error) => {
                    this.errorMessage();
                    reject(error);
                }
            });
        });
    }

    private restartTour(feature: string): Promise<void>
    {
        return new Promise((resolve, reject) => {
            this.apiService.get(`walkthroughs/${feature}/reset`).subscribe({
                next: (response) => {
                    this.authService.walkthroughs = response.data;
                    resolve();
                },
                error: (error) => {
                    this.errorMessage();
                    reject(error);
                }
            });
        });
    }

    private getWalkthrough(feature: string): any
    {
        let walkthrough = this.authService.walkthroughs.find(walkthrough => walkthrough.feature === feature);
        if (walkthrough) {
            walkthrough.items.map(walkthroughStep => {
                walkthroughStep.isAsync = true;
                walkthroughStep.asyncStepTimeout = 5000;

                return walkthroughStep;
            });
            return walkthrough;
        }

        return null;
    }

    private errorMessage(): void
    {
        this.toastService.error('An error occurred while starting the walkthrough.');
    }
}
